<template>
  <router-view />
</template>

<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
//   height: 100vh;
// }
// html {
//     box-sizing      : border-box;
//     width           : 100%;
//     height          : 100%;
//     line-height     : 1.5;
//     tab-size        : 4;
//     text-size-adjust: 100%;
// }

// body {
//     width  : 100%;
//     height : 100%;
//     margin : 0;
//     padding: 0;
//     font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
//         "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
//     line-height            : inherit;
//     -moz-osx-font-smoothing: grayscale;
//     -webkit-font-smoothing : antialiased;
//     text-rendering         : optimizelegibility;
// }</style>
