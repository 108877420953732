<template>
  <div class="home">
    <el-dialog v-model="dialogVisible" title="请选择需要连接的小球" width="30%" :showClose="false">
      <el-radio-group v-model="ballName">
        <el-radio value="robot_01">robot_01</el-radio>
        <el-radio value="robot_b2">robot_b2</el-radio>
      </el-radio-group>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="startSession()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <div class="videoArea">
      <video ref="videoElement" id="myVideo" v-show="videoShow" autoplay>
        您的浏览器不支持 video 标签。
      </video>
    </div>
    <div id="joystick-container">
    </div>
    <div class="goBackImg" v-if="false"><img style="width: 6vh; height: 6vh"
        src="../assets/img/ic_action_arrow_left.png" fit="fill" /></div>
    <div class="settingImg" v-if="false"><img style="width: 6vh; height: 6vh" src="../assets/img/settting.png"
        fit="fill" />
    </div>
    <div class="buttonArea">
      <el-button size="large" @click="dialogVisible = true" class="defaultButton">Start Session</el-button>
      <el-button class="defaultButton" size="large" @click="brake()">Brake</el-button>
      <el-button class="defaultButton" size="large" @click="deactivate()">Deactivate</el-button>
      <el-button class="defaultButton" @click="toggleAudio()">{{ audioState }}</el-button>
      <el-button class="defaultButton" @click="rotateVideo()">旋转</el-button>
      <el-button class="defaultButton" @click="accelerate()">加速</el-button>
      <el-button class="defaultButton" @click="decelerate()">减速</el-button>
    </div>
    <div class="bottomButtonArea">
      <div @click="togglePlayPause()">
        <img style="width: 5vw; height: 5vw" :src="isPlaying ? pauseImg : startImg" fit="fill" />
      </div>
      <div v-if="false">
        <img style="width: 5vw; height: 5vw" src="../assets/img/ic_action_camera.png" fit="fill" />
      </div>
      <div v-if="false">
        <img style="width: 5vw; height: 5vw" src="../assets/img/ic_action_video.png" fit="fill" />
      </div>
      <div v-if="false">
        <img style="width: 5vw; height: 5vw" src="../assets/img/ic_action_sun_dark.png" fit="fill" />
      </div>
      <span class="speedText">{{ currentVel }}</span>

    </div>
    <div class="rightUporDown">
      <div class="upPngView">
        <img style="width: 9vw; height: 9vw" :src="isupPngViewPress ? upwardPressImg : upwardImg" fit="fill" />
      </div>
      <div class="downPngView"><img style="width: 9vw; height: 9vw"
          :src="isdownPngViewPress ? downwardPressImg : downwardImg" fit="fill">
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted } from 'vue'
import nipplejs from 'nipplejs'
const dialogVisible = ref(false)

const ballName = ref('robot_01')
const isAudioOn = ref(false)
const audioState = ref('不静音')
const videoElement = ref(null)
const videoShow = ref(false)
let rotationAngle = 0 // 初始化旋转角度
const startImg = require('../assets/img/ic_action_playback_play.png')
const pauseImg = require('../assets/img/ic_action_playback_pause.png')
const isPlaying = ref(true)
const upwardImg = require('../assets/img/upward.png')
const upwardPressImg = require('../assets/img/upward_pressed.png')
const isupPngViewPress = ref(false)
const downwardImg = require('../assets/img/downward.png')
const downwardPressImg = require('../assets/img/downward_pressed.png')
const isdownPngViewPress = ref(false)
// 线性速度，角速度，状态。用于传输控制小球数据的变量
const linearVel = ref(0)
const angularVel = ref(0)
const stateButton = ref('None')
const timerId = ref(null)
const currentVel = ref(0)

onMounted(() => {
  const joystickContainer = document.getElementById('joystick-container')
  const option = {
    zone: joystickContainer,
    mode: 'static', // 静态模式，摇杆固定在屏幕上
    position: { top: '70%', left: '20%' }, // 摇杆的初始位置
    color: 'orange', // 摇杆的颜色
    size: 150
  }
  // 初始化虚拟摇杆
  const joystick = nipplejs.create(option)

  joystick
    .on('start', function (evt, data) {
      console.log('start===============', data)
    })
    .on('move', function (evt, data) {
      // direction有不存在的情况
      // console.log(data.direction)
      // console.log('move===============', data.angle.degree)
      if (data.angle.degree >= 0 && data.angle.degree <= 270) {
        // 线性映射到0到-128
        const targetValue = ((data.angle.degree - 90) / 180) * (-128)
        // 因为我们希望270度映射到-128，需要额外减去128
        // targetValue = targetValue - 128
        angularVel.value = Math.floor(targetValue)
        stateButton.value = 'None'
      } else {
        const targetValue = 127 - ((data.angle.degree - 271) / 89) * 63
        angularVel.value = Math.floor(targetValue)
        stateButton.value = 'None'
      }
    })
    .on('end', function (evt, data) {
      angularVel.value = 0
      console.log('end===============', data)
    })
})
let video
document.addEventListener('DOMContentLoaded', (event) => {
  // 获取元素--前进按钮
  // const touchDiv = document.getElementById('touchDiv')
  const touchDiv = document.querySelector('.upPngView')

  touchDiv.addEventListener('touchstart', function (event) {
    isupPngViewPress.value = true
    if (currentVel.value === 0) {
      currentVel.value = 1
      linearVel.value = 1
      stateButton.value = 'None'
    } else {
      linearVel.value = currentVel.value
      stateButton.value = 'None'
    }
    console.log('前进触摸开始了！')
  })
  touchDiv.addEventListener('touchend', function (event) {
    isupPngViewPress.value = false
    console.log('前进触摸结束了！')
    linearVel.value = 0
  })
  touchDiv.addEventListener('mousedown', function (event) {
    isupPngViewPress.value = true
    if (currentVel.value === 0) {
      currentVel.value = 1
      linearVel.value = 1
      stateButton.value = 'None'
    } else {
      linearVel.value = currentVel.value
      stateButton.value = 'None'
    }
    console.log('前进触摸开始了！')
  })
  touchDiv.addEventListener('mouseup', function (event) {
    isupPngViewPress.value = false
    linearVel.value = 0
    console.log('前进触摸结束了！')
  })
  // 获取元素--后退按钮
  const downPngView = document.querySelector('.downPngView')

  // 按下事件
  downPngView.addEventListener('touchstart', function (event) {
    isdownPngViewPress.value = true
    if (currentVel.value === 0) {
      currentVel.value = 1
      linearVel.value = -1
      stateButton.value = 'None'
    } else {
      linearVel.value = -currentVel.value
      stateButton.value = 'None'
    }
    console.log('后退触摸开始了！')
  })

  // 松开事件
  downPngView.addEventListener('touchend', function (event) {
    isdownPngViewPress.value = false
    linearVel.value = 0
    console.log('后退触摸结束了！')
  })
  // 按下事件
  downPngView.addEventListener('mousedown', function (event) {
    isdownPngViewPress.value = true
    if (currentVel.value === 0) {
      currentVel.value = 1
      linearVel.value = -1
      stateButton.value = 'None'
    } else {
      linearVel.value = -currentVel.value
      stateButton.value = 'None'
    }
    console.log('后退触摸开始了！')
  })

  // 松开事件
  downPngView.addEventListener('mouseup', function (event) {
    isdownPngViewPress.value = false
    linearVel.value = 0
    console.log('后退触摸结束了！')
  })
  // 获取元素--视频组件
  video = document.getElementById('myVideo')
})
/* eslint-env browser */
var state = {
  remote_desc_set: false,
  remote_ice: []
}

function sendMsg(msg_type, msg_body) {
  if (state.socket) {
    const msg = JSON.stringify({
      msg_type,
      msg_body
    })
    state.socket.send(msg)
  }
}
let localStream

function rtcconn(robotId) {
  // 与信令服务器进行连接
  state.socket = new WebSocket(
    // `ws://222.201.144.170:8020/robot_webrtc/${robotId}/signaling`
    `wss://robotmgr2.mcurobot.com/robot_webrtc/${robotId}/signaling`
  )
  state.socket.onopen = () => {
    console.log("websocket连接打开了================================");
    sendMsg('rtc_setup', '')
  }
  state.socket.onmessage = async (event) => {
    const msg = JSON.parse(event.data)
    switch (msg.msg_type) {
      case 'rtc_setup':
        console.log('"rtc_setup================================"')
        const { stun, turn } = JSON.parse(msg.msg_body)
        localStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            channelCount: 1,
            sampleRate: 8000,
          }
        });


        state.pc = new RTCPeerConnection({
          iceServers: [
            {
              urls: [stun]
            },
            turn
          ]
        })

        localStream.getTracks().forEach(track => {
          state.pc.addTrack(track, localStream);
          if (track.kind === 'audio') { // 确保只修改音频轨道
            track.enabled = isAudioOn.value
          }
        });

        // 向远端同步ice候选信息
        state.pc.onicecandidate = (event) => {
          if (event.candidate) {
            sendMsg('ice', JSON.stringify(event.candidate))
          }
        }
        state.pc.addTransceiver('video', { direction: 'recvonly' })
        state.pc.addTransceiver('audio', { direction: 'sendrecv' })
        state.pc.ontrack = function (event) {
          // var el = document.createElement(event.track.kind)
          // el.srcObject = event.streams[0]
          // el.autoplay = true
          // el.controls = true

          // video.appendChild(el)
          videoShow.value = true
          video.srcObject = event.streams[0]
        }

        state.datachannel = state.pc.createDataChannel("robot_cmd")
        state.datachannel.onopen = () => {
          timerId.value = setInterval(sendMessageToBackend, 500)
        }
        state.datachannel.onerror = (err) => { console.error(err) }
        state.pc
          .createOffer()
          .then((desc) => {
            // 保存本地sdp信息
            return state.pc.setLocalDescription(desc)
          })
          .then(() =>
            sendMsg('sdp', JSON.stringify(state.pc?.localDescription))
          )
        break
      case 'sdp':
        // 同步远端sdp信息
        state.pc
          ?.setRemoteDescription(
            new RTCSessionDescription(JSON.parse(msg.msg_body))
          )
          .catch((err) => console.log(err))
        state.remote_desc_set = true
        state.remote_ice.forEach((desc) => {
          state.pc
            ?.addIceCandidate(desc)
            .catch((err) => console.log(err))
        })
        state.remote_ice = []
        break
      case 'ice':
        // 同步远端ice候选信息
        const candidate = new RTCIceCandidate(JSON.parse(msg.msg_body));
        if (state.remote_desc_set) {
          state.pc?.addIceCandidate(candidate).catch((err) => console.log(err))
        } else {
          state.remote_ice.push(candidate)
        }

        break
      default:
        break
    }
  }
  state.socket.onerror = (err) => {
    console.log(err)
  }
}

const startSession = () => {
  // rtcconn("robot_b2")
  dialogVisible.value = false
  console.log("ballName.value==============", ballName.value);
  rtcconn(ballName.value)
}

/*
 * 控制命令JSON：
 * {
 *   linear_vel: -10到10的线速度
 *   angular_vel: -128到127的角速度
 *   button: None或者Deactivate或者Brake
 * }
 */
window.forward = () => {
  linearVel.value = 1
  angularVel.value = 20
  stateButton.value = 'None'
  // state.datachannel?.send(JSON.stringify({
  //   linear_vel: 0,
  //   angular_vel: 10,
  //   button: "None"
  // }))
}

const brake = () => {
  linearVel.value = 0
  angularVel.value = 0
  stateButton.value = 'Brake'
  // state.datachannel?.send(JSON.stringify({
  //   linear_vel: 0,
  //   angular_vel: 0,
  //   button: "Brake"
  // }))
}


const deactivate = () => {
  linearVel.value = 0
  angularVel.value = 0
  stateButton.value = 'Deactivate'
  // state.datachannel?.send(JSON.stringify({
  //   linear_vel: 0,
  //   angular_vel: 0,
  //   button: "Deactivate"
  // }))
}
const sendMessageToBackend = () => {
  console.log('linearVel.value===========', linearVel.value)
  console.log('传输的值===========', {
    linear_vel: linearVel.value,
    angular_vel: angularVel.value,
    button: stateButton.value
  })
  state.datachannel?.send(JSON.stringify({
    linear_vel: linearVel.value,
    angular_vel: angularVel.value,
    button: stateButton.value
  }))
}
onUnmounted(() => {
  if (timerId.value) {
    clearInterval(timerId.value)
    timerId.value = null
  }
})
// 控制视频播放
const togglePlayPause = () => {
  if (video.paused || video.ended) {
    // 如果视频是暂停状态或已结束，则播放
    isPlaying.value = true
    video.play()
  } else {
    // 如果视频正在播放，则暂停
    isPlaying.value = false
    video.pause()
  }
}
// 控制这端录音是否发送
const toggleAudio = () => {
  if (localStream) {
    isAudioOn.value = !isAudioOn.value
    if (!isAudioOn.value) { audioState.value = '不静音' } else {
      audioState.value = '静音'
    }
    localStream.getTracks().forEach(track => {
      if (track.kind === 'audio') { // 确保只修改音频轨道
        track.enabled = isAudioOn.value
      }
    })
  }
}
// 控制视频播放画面旋转角度
const rotateVideo = () => {
  rotationAngle += 180
  rotationAngle %= 360 // 确保角度在0到360度之间循环
  videoElement.value.style.transform = `rotate(${rotationAngle}deg)`
}
// 加速
const accelerate = () => {
  if (currentVel.value >= 0 && currentVel.value < 10) {
    currentVel.value++
  }
}
// 减速
const decelerate = () => {
  if (currentVel.value > 0 && currentVel.value <= 10) {
    currentVel.value--
  }
}
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  position: relative;

  .videoArea {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    #myVideo {
      width: 100%;
      height: 100%;
    }

  }

  #joystick-container {
    position: absolute;
    bottom: 15%;
    left: 13%;
  }

  .goBackImg {
    position: absolute;
    top: 5%;
    left: 5%;
  }

  .settingImg {
    position: absolute;
    top: 5%;
    right: 5%;
  }

  .buttonArea {
    position: absolute;
    top: 15%;
    left: 5%;
    display: flex;
    flex-direction: column;

    :deep(.el-button+.el-button) {
      margin-left: 0px;
      margin-top: 1vh;
    }

    .defaultButton {
      border-color: #dcdfe6;
      background-color: #ffffff;
      color: #606266;
    }

    .defaultButton :hover {
      border-color: #dcdfe6;
      background-color: #ffffff;
      color: #606266;
    }

  }

  .bottomButtonArea {
    position: absolute;
    width: 10vw;
    height: 5vw;
    bottom: 5%;
    right: 45%;
    display: flex;
    justify-content: space-between;

    .speedText {
      text-align: center;
      font-size: 40px;
      height: 5vw;
      line-height: 5vw;
      color: #F7C90D;
    }
  }

  .rightUporDown {
    position: absolute;
    bottom: 5%;
    right: 5%;

    .upPngView {
      width: 9vw;
      height: 9vw;
    }

    .downPngView {
      width: 9vw;
      height: 9vw;
    }
  }
}
</style>
